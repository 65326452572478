// extracted by mini-css-extract-plugin
export var form = "course-request-form-module--form--g7Ktl";
export var form__section = "course-request-form-module--form__section--NOtzW";
export var form__container = "course-request-form-module--form__container--62emN";
export var form__info = "course-request-form-module--form__info--iHxCR";
export var form__title = "course-request-form-module--form__title--CsixH";
export var form__desc = "course-request-form-module--form__desc--knyBR";
export var form__logo = "course-request-form-module--form__logo--jvlJf";
export var form__formWrapper = "course-request-form-module--form__form-wrapper--hEes7";
export var form__formTitle = "course-request-form-module--form__form-title--HHs8y";
export var form__formLink = "course-request-form-module--form__form-link--YSG3R";
export var form__formButton = "course-request-form-module--form__form-button--GPlph";
export var form__gradient = "course-request-form-module--form__gradient--pdAEP";