import PropTypes from "prop-types";
import {useCallback, useState} from "react";
import * as React from "react";
import Logo from "../../assets/svg/logo-white.svg";
import Section from "../Layout/Section";
import CheckboxField from "../Form/Fields/Checkbox";
import Input from "../Form/Fields/Input";
import StatusModal from "../Form/StatusModal";
import Form from "../Form";
import TemplateString from "../Common/TemplateString";
import Link from "../UI/Link";
import * as formStyles from "./course-request-form.module.css";

const fields = [
    {
        name: "name",
        render({control, register, error, key}) {
            const rules = {
                minLength: {
                    value: 3,
                    message: "Имя должно быть не короче 2-ух символов",
                },
            };

            return (
                <Input
                    register={register}
                    control={control}
                    name={this.name}
                    placeholder="Как вас зовут?"
                    required="Имя обязательно к заполнению"
                    rules={rules}
                    error={error}
                    key={key}
                />
            );
        },
    },
    {
        name: "company",
        render({control, register, error, key}) {
            return (
                <Input
                    register={register}
                    control={control}
                    name={this.name}
                    placeholder="Название вашей компании"
                    required="Название компании обязательно к заполнению"
                    error={error}
                    key={key}
                />
            );
        },
    },
    {
        name: "phone",
        render({control, register, error, key}) {
            const rules = {
                pattern: {
                    value: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}/i,
                    message: "Номер телефона должен соответствовать шаблону +7 (123) 456-78-90",
                },
            };

            return (
                <Input
                    register={register}
                    control={control}
                    type="tel"
                    name={this.name}
                    placeholder="Ваш номер телефона"
                    required="Номер телефона обязателен к заполнению"
                    rules={rules}
                    error={error}
                    key={key}
                />
            );
        },
    },
    {
        name: "email",
        render({control, register, error, key}) {
            const rules = {
                pattern: {
                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
                    message: "Введен некорректный email адрес",
                },
            };

            return (
                <Input
                    register={register}
                    control={control}
                    type="email"
                    name={this.name}
                    placeholder="Ваш email"
                    required="Email обязателен к заполнению"
                    rules={rules}
                    error={error}
                    key={key}
                />
            );
        },
    },
    {
        name: "message",
        render({control, register, key}) {
            return (
                <Input
                    register={register}
                    control={control}
                    type="textarea"
                    name={this.name}
                    showLabel={false}
                    label="Вопрос или пожелание"
                    placeholder="Здесь вы можете оставить свой вопрос или пожелание к обучению"
                    key={key}
                />
            );
        },
    },
    {
        name: "privacy",
        render({register, error, key}) {
            const label = (
                <span>
                    Я принимаю условия{" "}
                    <Link to="/privacy" withArrow={false} className={formStyles.form__formLink}>
                        Политики в отношении обработки персональных данных
                    </Link>
                </span>
            );

            return (
                <CheckboxField
                    register={register}
                    label={label}
                    name={this.name}
                    required="Согласие с политикой в отношении обработки персональных данных обязательно"
                    error={error}
                    key={key}
                />
            );
        },
    },
];

const CourseRequestForm = ({title, desc}) => {
    const [showStatusModal, setStatusModal] = useState(false);
    const [formStatus, setFormStatus] = useState("");

    const closeStatusModal = () => {
        setStatusModal(false);
    };

    const scrollToForm = useCallback(() => {
        const form = document.querySelector("#request-form");
        if (!form) {
            return;
        }

        form.scrollIntoView({alignToTop: true, behavior: "smooth"});
    }, []);

    const handler = useCallback(
        async (data) => {
            const requestData = {
                title: "Как пройти обучение",
                department: "education",
                fields: [
                    {
                        label: "Страница",
                        value: window.location.href,
                    },
                    {
                        label: "Как вас зовут?",
                        value: data.name,
                    },
                    {
                        label: "Название вашей компании",
                        value: data.company,
                    },
                    {
                        label: "Ваш номер телефона",
                        value: data.phone,
                    },
                    {
                        label: "Ваш email",
                        value: data.email,
                    },
                    {
                        label: "Ваше сообщение",
                        value: data.message,
                    },
                ],
            };
            const response = await fetch(`${process.env.GATSBY_API_URL}/mailer/send`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify(requestData),
            });
            const json = await response.json();

            setStatusModal(true);
            scrollToForm();
            if (!json.success) {
                console.error("Error");
                setFormStatus("error");

                return;
            }

            setFormStatus("success");
        },
        [scrollToForm],
    );

    return (
        <Section className={formStyles.form__section} containerClassName={formStyles.form__container} id="request-form">
            {!showStatusModal && (
                <>
                    <div className={formStyles.form}>
                        <div className={formStyles.form__info}>
                            <div>
                                <h2 className={formStyles.form__title}>
                                    <TemplateString template={title} />
                                </h2>
                                <p className={formStyles.form__desc}>
                                    <TemplateString template={desc} />
                                </p>
                            </div>
                            <Logo className={formStyles.form__logo} />
                        </div>
                        <div className={formStyles.form__formWrapper}>
                            <div>
                                <h4 className={formStyles.form__formTitle}>Заполните данные формы</h4>
                                <Form fields={fields} buttonText="Отправить заявку" onSubmit={handler} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={formStyles.form__gradient} />
                    </div>
                </>
            )}

            {showStatusModal && (
                <StatusModal onClose={closeStatusModal} status={formStatus} variant="school" showImages={false} />
            )}
        </Section>
    );
};

CourseRequestForm.defaultProps = {
    title: "Как пройти обучение",
    desc: "Чтобы пройти курсы, заполните заявку, и мы предоставим доступ.",
};

CourseRequestForm.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
};

export default CourseRequestForm;
